import styled, { css } from "styled-components";
import { EventDate, EventContentContainer } from "../MainEvents/styles";
import { EventDetailsContainer } from "../EventDetails/styles";
import { ImageContainer } from "../ChallengesDashboard/styles";
import { StyledTabContainer } from "../../components/ReportsV2/styles";
import { CustomTextArea, CommentContent } from "../EventDetails/styles";
import { UserButton } from "../EngagementSection/styles";
import { ImageInput } from "../CreateEvent/styles";

const RegularFont = "Rubik-Regular";
const BoldFont = "Rubik-Medium";
const ExtraBold = "Rubik-Bold";

const Layout = styled.div`
  padding: 32px 40px 20px 32px;
  padding: ${({ padding }) => (padding ? "0px" : "32px 40px 20px 32px")};
  width: 100%;
  float: left;
  font-family: ${RegularFont};
  margin-bottom: 100px;

  @media (max-width: 500px) {
    padding: 30px 10px;
  }
`;

const ImageWrapper = styled(ImageContainer)`
  display: inline-block;
  height: auto;
  @media (min-width: 1200px) {
    max-height: 300px;
  }

  @media (max-width: 767px) and (min-width: 530px) {
    height: 100%;
  }

  > img {
    height: 100%;
    cursor: default;
  }
`;

const ChallengeDetailsContainer = styled(EventDetailsContainer)`
  &:first-child {
    margin-top: 0;
  }

  @media (min-width: 767px) {
    height: unset;
    display: flex;
  }

  @media (max-width: 767px) {
    height: auto;
  }
`;
const CommentInnerContainer = styled.div`
  width: 100%;
  position: relative;
  background: white;
  border: ${({ border }) => (border ?border : "1px solid rgba(0, 0, 0, 0.09)")};
  border-radius: 8px;
`;

const ChallengeDate = styled(EventDate)`
  margin-top: 10px;
  line-height: 24px;
  > div {
    > svg {
      margin-top: 5px;
    }
  }
`;

const ChallengeContentContainer = styled(EventContentContainer)`
  width: 30%;
  padding: 5px 15px 14px 37px;

  @media (max-width: 767px) and (min-width: 530px) {
    height: auto;
  }

  @media (max-width: 530px) {
    width: 100%;
    padding-left: 15px;
    padding-top: 25px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    margin-left: 30%;
  }
`;

const ChallengeDescription = styled.div`
  float: right;
  width: 40%;
  display: inline-block;
  padding: 7px 0 16px 15px;
  height: auto;

  @media (min-width: 951px) and (max-width: 1010px) {
    padding: 7px 20px 16px 10px;
  }

  > span {
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgb(51, 51, 51);
    font-family: ${BoldFont};
  }

  > button {
    float: right;
  }

  > div {
    color: rgb(153, 153, 153);
    margin-top: 5px;
  }

  @media (max-width: 766px) {
    width: 100%;
    padding-top: 25px;
  }
`;

const ContentSection = styled.div`
  float: left;
  width: calc(50% - 28px);
  overflow: hidden;
  margin-top: 40px;
  margin-right: 28px;
  &:last-child {
    margin-right: 0;
    margin-left: 28px;
  }

  .content-top {
    background: none;
  }

  > div {
    .bold {
      font-weight: bold;
      color: #333333;
      letter-spacing: 1px;
      font-size: 16px;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;
    margin-right: 0;
    height: auto;
    &:last-child {
      margin-left: 0;
    }
  }

  .top {
    margin-top: 30px;
  }

  .content {
    width: 100%;
    background-color: white;
    float: left;
    box-shadow: 1px 1.732px 46px rgba(0, 0, 0, 0.1);
    > h3 {
      font-size: 24px;
      padding: 35px 0 10px 25px;
      margin: 0;

      @media (max-width: 500px) {
        font-size: 20px;
        padding: 20px 10px 0 10px;
      }
    }
  }
`;

const SectionHeader = styled.div`
  float: left;
  width: 100%;
  border-bottom: 1px solid #dcd8d8;
  padding: 20px 27px 20px 25px;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  > span {
    font-size: 24px;
    letter-spacing: 0px;
    color: rgb(0, 0, 0);
    float: left;
    line-height: 50px @media (max-width: 500px) {
      font-size: 20px;
    }
  }

  > div {
    float: right;
    font-size: 20px;
    text-align: right;
    width: 50%;
    @media (max-width: 500px) {
      font-size: 16px;
    }
    > div {
      > span {
        font-size: 24px;
        letter-spacing: 0px;
        font-family: ${BoldFont};
        color: rgb(21, 159, 202);

        @media (max-width: 500px) {
          font-size: 20px;
        }
      }
    }
  }

  @media (max-width: 500px) {
    padding: 10px;
  }
`;

const BehindContent = styled.div`
  text-align: ${(props) => (props.center ? "center" : "left")};
  font-size: 12px;
  letter-spacing: 0px;
  color: rgb(153, 153, 153);
  > div {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    > span {
      &:first-child {
        font-family: ${BoldFont};
        color: rgb(51, 51, 51);
      }
      text-transform: capitalize;
    }

    > div {
      text-transform: capitalize;
      display: inline-block;
      margin-left: 3px;
    }
  }

  ${({ center }) =>
    center &&
    css`
      > div {
        text-align: right;
      }
    `}
`;

const RankContainer = styled.div`
  float: left;
  width: 100%;
  padding: 10px 15px 15px;
  border-bottom: 1px solid #dcd8d8;

  img {
    float: left;
    width: 50px;
    height: 50px;
    border-radius: 50%;

    @media (max-width: 500px) {
      width: 40px;
      height: 40px;
    }
  }

  @media (max-width: 500px) {
    padding: 10px 0;
  }
`;

const Ranking = styled.span`
  float: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: rgb(153, 153, 153);
  font-family: ${BoldFont};
  margin-right: 10px;
  width: 60px;
  line-height: 50px;

  @media (max-width: 500px) {
    width: 25px;
    font-size: 12px;
    line-height: 40px;
    margin-right: 7px;
  }
`;

const ActivityBar = styled.div`
  height: 8px;
  width: 100%;
  background-color: #ededed;
  border-radius: 30px;
  display: inline-block;

  @media (max-width: 1280px) {
    width: 100%;
  }
`;

const InnerActivityBar = styled.div`
  height: 8px;
  width: ${(props) => props.width};
  background-color: #7cbf41;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;

  ${(props) =>
    props.width === "100%" &&
    css`
      border-radius: 30px;
    `}
`;

const ActivityContainer = styled.div`
  float: left;
  width: calc(100% - 130px);
  margin-left: 10px;

  ${({ survivorChallenge }) =>
    survivorChallenge &&
    css`
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;

      > div:first-child {
        white-space: nowrap;
        overflow: hidden;
        padding-right: 5px;

        > span {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    `}

  @media (max-width: 500px) {
    width: calc(95% - 80px);
    margin-left: 8px;
  }
`;

const UserName = styled.span`
  float: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: rgb(51, 51, 51);
  font-family: ${BoldFont};
  text-transform: capitalize;

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

const CoveredChallenge = styled.div`
  float: right;
  text-align: right;
  width: auto;
  font-size: 13px;
  color: rgb(153, 153, 153);
  > span {
    font-size: 15px;
    letter-spacing: 0px;
    color: rgb(51, 51, 51);
    text-align: right;

    @media (max-width: 500px) {
      font-size: 15px;
    }
  }

  @media (max-width: 500px) {
    font-size: 13px;
  }

  ${({ weekTotal }) =>
    weekTotal &&
    css`
      display: block;
      float: none;
      text-align: right;
    `}
`;

const LeaderBoardSection = styled.div`
  float: left;
  width: calc(100% - 20px);
  overflow-y: auto;
  height: auto;
  max-height: calc(940px - 120px);
  margin: 10px 10px 20px;

  @media (max-width: 500px) {
    padding: 10px;
    margin: 10px 0;
    width: 100%;
  }

  ::-webkit-scrollbar {
    width: 7px;
    height: 5px;

    @media (max-width: 500px) {
      width: 5px;
    }
  }

  ::-webkit-scrollbar-thumb {
    background: #c6c2c2;
    cursor: pointer;
    border-radius: 2.5px;
  }

  ::-webkit-scrollbar-track {
    background: #f2f0f0;
    border-radius: 2.5px;
  }
`;

const Description = styled.div`
  color: rgb(153, 153, 153);
  margin-top: 5px;
  height: auto;
`;

const CommentContentContainer = styled.div`
  float: left;
  width: 100%;
  background: #EFEFF0;
`;

const CommentContentContainerTeam = styled.div`
  float: left;
  width: 100%;
  margin-bottom: 20px;
  ${
  "" /* >div:first-child{
    width: 100%;
  } */
}
  >div:nth-child(2) {
    > div:first-child {
      ${"" /* border: 1px solid black; */}
      height: auto;
    }
  }
`;

const LeaderBoardItem = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 11px;
  background-color: ${({ solo, completed }) =>
    completed ? "#7cbf41" : solo ? "#F24343" : "#cccccc"};
  float: ${({ survivor }) => (survivor ? "none" : "left")};
  text-align: center;
  margin: ${({ survivor }) => (survivor ? "0 0 0 auto" : "1px 0.15vw")};

  > i {
    line-height: 20px;
    color: white;
    font-size: 12px;
  }

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  .fa-times {
    font-size: 16px;
  }

  @media (max-width: 500px) {
    width: 15px;
    height: 15px;
    > i {
      font-size: 10px;
    }

    .fa-times {
      font-size: 12px;
      margin-top: 0;
    }
  }
`;

const LeaderboardItemContainer = styled.div`
  float: ${({ auto }) => (auto ? "right" : "left")};
  width: ${({ auto }) => (auto ? "auto" : "100%")};
  @media (max-width: 400px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ProfileImage = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  float: left;

  @media (max-width: 500px) {
    width: 40px;
    height: 40px;
  }
`;
const FormContainer = styled.form`
  width: ${({ editChallenge }) => (editChallenge ? "100%" : "63%")};
  float: left;
  display: block;
  background-color: ${({ background }) => (background ? '' : 'white')};
  box-shadow: ${({ margin }) =>
    margin ? "" : "1px 1.732px 46px rgba(0,0,0,0.18)"};
  margin-bottom: ${({ margin }) => (margin ? "25px" : "0px")};
  margin-top: ${({ marginTop }) => (marginTop ? "15px" : "0px")};
  border-radius: 6px;
  .calendar{
    display: flex;
    float: left;
  }
  
  > div > ul {
    margin-top: 20px;
  }
  
  @media only screen and (max-width: 767px) {
   width: 100%;
   margin-bottom: 20px;
   margin-top: 20px;
  }
   
    @media (max-width: 500px) {
      font-size: 20px;
      padding: 20px 10px 0 10px;
    }
  }
`;

const TopContainer = styled.div`
background:white;
> h3 {
  
  margin: 0;
  font-family: 'Rubik-medium';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #0D4270;

}
border-bottom: ${({ border }) => (border ? "0" : "1px solid rgba(156, 156, 156, 0.4)")};
display:flex;
justify-content:space-between;
align-items:center;
width:100%;
height:74px;
padding:0 25px;
margin-bottom:20px;
>div{
  display:flex;
justify-content:center;
align-items:center;
}
>div img{
  width:40px;
  height:40px;
  margin-left:-12px;
  border-radius:50%
  border:2px solid #fff
}
>div >div{
  width:45px;
  height:40px;
  margin-left:-12px;
  border-radius:20px;
  background: linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
border: 2px solid #FFFFFF;
border-radius: 20px;
display: flex;
justify-content: center;
align-items: center;
color: white;
font-family: rubik-medium;
}
`;

const WeekDashboard = styled.div`
  float: left;
  width: 100%;
  height: 111px;
  box-shadow: 1px 1.732px 46px rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  .split {
    float: left;
    width: calc(100% / 7);
    display: block;
    .block {
      float: left;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      > div {
        width: 100%;
        float: left;
      }
    }
  }
`;

const DateCircle = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 23px;
  margin: 0 auto;
  background-color: ${({ active }) => (active ? "#159fca" : "#efefef")};
  font-size: 20px;
  line-height: 12px;
  color: ${({ active }) => (active ? "#ffffff" : "#666666")};
  font-weight: 400;
  text-align: center;
  display: block;
  cursor: ${({ clickableDate }) => clickableDate && "pointer"};
  @media (max-width: 400px) {
    width: 33px;
    height: 33px;
    border-radius: 16px;
    font-size: 16px;
  }
  .flex {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;

const DateShower = styled.div`
  font-size: 16px;
  line-height: 12px;
  color: ${({ active }) => (active ? "#333333" : "#999999")};
  font-weight: ${({ active }) => (active ? "600" : "400")};
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
  @media (max-width: 400px) {
    font-size: 13px;
  }
`;

const HeaderDashboard = styled.div`
  width: 100%;
  float: left;
  padding: 20px;
  display: flex;
  align-items: center;

  .leftText {
    text-algn: left;
    font-size: 24px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 400;
    display: inline-block;
    width: 50%;
  }
  .rightText {
    display: flex;
    justify-content: flex-end;
    width: 50%;
    font-size: 14px;
    text-align: right;
    letter-spacing: 0px;
    color: #333333;
    font-weight: 400;
    .child {
      display: inline-block;
      text-align: left;
    }
    .remainingWeek {
      font-size: 28px;
      letter-spacing: 0px;
      color: #159fca;
      font-weight: 600;
      margin-right: 10px;
    }
  }
`;

const DescriptionTraining = styled.div`
  margin-top: 30px;
  .coach {
    font-size: 24px;
    letter-spacing: 0px;
    color: #333333;
    text-align: center;
  }
  .date {
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 38px;
    color: #159fca;
    text-align: center;
  }
  .tip {
    width: 100%;
    font-size: 16px;
    letter-spacing: 0px;
    color: #333333;
    font-style: italic;
    text-align: left;
    margin-bottom: 30px;
  }
  .didYouComplete {
    font-size: 16px;
    color: #484856;
    font-family: ${ExtraBold};
    text-align: center;
    text-transform: none;
    margin-bottom: 30px;
  }
  .habit {
    font-size: 18px;
    letter-spacing: 0px;
    color: #333333;
    text-align: center;
    margin-bottom: 15px;
  }
  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 10px 30px;
  }
`;

const Button = styled.button`
  width: 122px;
  height: 50px;
  border-radius: 25px;
  background: ${({ noButton }) =>
    noButton ? "#aaaaaa" : "linear-gradient(0deg, #1E76AB, #1E76AB)"};
  margin-left: ${({ marginLeft }) => (marginLeft ? "15px" : 0)};
  font-size: 12px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  border: none;
  text-transform: uppercase;
  letter-spcaing: 1.6px;
  font-family: ${RegularFont};
  outline: none;
  &:first-child {
    margin-right: 44px;
  }
`;

const ChallengeContentLeftContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px 25px;
  height: 241px;
  width: 100%;
`;
const TopRowContainer = styled.div`
  display: flex;
  padding-bottom: 20px;
  justify-content: space-between;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;
const ChallengeTitle = styled.div`
  font-family: ${BoldFont};
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: #ffffff;
  text-transform: capitalize;
`;
const SyncTimeContainer = styled.div`
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 10px;
  width: 100%;
  left: 0;
  padding: 0 24px;
  > span {
    color: #fff;
    font-family: ${RegularFont};
    font-size: 14px;
    line-height: 17px;
    > img {
      width: 14px;
      height: 14px;
    }
    > span {
      margin-left: 5px;
    }
    @media (max-width: 1100px) and (min-width: 1000px) {
      margin-bottom: 10px;
    }
    @media (max-width: 550px) {
      margin-bottom: 10px;
    }
  }
  @media (max-width: 1100px) and (min-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 550px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const StyledTabs = styled(StyledTabContainer)`
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? "" : "1246px")};
  margin: auto;
  margin-bottom: 10px;
  height: 70px;
  border-bottom: none;
  padding: 16px 10px 12px 18px;
  font-size: 12px;
  text-transform: capitalize;
  color: rgb(153, 153, 153);
  background-color: rgb(255, 255, 255);
  li > button {
    border: none !important;
    font-size: 16px;
    padding: 12px;
    color: rgb(153, 153, 153);
    &:hover{
      color: rgb(153, 153, 153);
    }
  }

  .nav-item{
    font-family: Rubik-Medium; 
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    border-bottom: none;
    height: 40px;
    border-radius: 3px;
    -webkit-box-align: center;
    margin-right: 3vw;
  }

  .nav-item > .active {
    font-family: Rubik-Medium;
    background: rgba(0, 47, 71, 0.1);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    height: 40px;
    text-align: center;
    color: rgb(13, 66, 112);
    border-bottom: none;    
    border-radius: 3px;
    -webkit-box-align: center;
  }

  .tab-content {
    position: relative;
  }

  .company-container {
    float: right;
    margin-top: -53px;
    margin-right: 43px;

    @media (max-width: 639px) {
      float: left;
      margin-left: 63px;
    }
    @media (max-width: 800px) {
      float: right;
      
    }
    @media (max-width: 500px) {
      display: inline-block;
      float: none;
      margin: -27px 41px 0 33px;
      padding-top: 10px;
    }
    @media (max-width: 600px) and (min-width: 501px) {
      display: inline-block;
      float: right;
      margin: 0 41px 0 33px;
      padding-top: 10px;
    }
  }

  > ul {
    margin: 0;
    border-radius: 6px;
    @media (max-width: 600px) {
      min-height: 100px;
      margin-top: 30px;
      padding: 4px 0px;
    }
    > li {
      @media (max-width: 600px) {
        text-align: center;
        float: none;
        width: 107px;
        margin: 0 auto;
      }
      > a {
        font-size: 15px;
        color: #CCCBDO;
        text-transform: none;
        font-family: ${BoldFont};
        margin-right: 3vw;
        padding-top: 12px;

        @media (max-width: 600px) {
          padding-left: 0;
          padding-right: 0;
          margin: 0;
        }
      }
    }

    ${
  "" /* .active {
  > a {
    &:hover,
    &:active,
    &:focus {
      color: rgb(51, 51, 51);
    }
  }
} */
}
  }

  li.first-tab.active {
    > a {
      font-family: "Rubik-Medium";
      background: rgba(0, 47, 71, 0.1);
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #0d4270;
      border-bottom: none;
      padding: 12px;
      height: 40px;
      border-radius: 3px;
      align-items: center;
    }
  }
  li.second-tab.active {
    > a {
      font-family: "Rubik-Medium";
      background: rgba(0, 47, 71, 0.1);
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #0d4270;
      border-bottom: none;
      padding: 12px;
      height: 40px;
      border-radius: 3px;
      align-items: center;
    }
  }
  li.third-tab.active {
    > a {
      color: #0169b2;
      border-bottom: 3px solid #0169b2;
      font-family: ${BoldFont};
    }
  }
`;
const ChallengeDetailsContainerV2 = styled(EventDetailsContainer)`
  background-color: transparent;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-top: 0;
  display: block;
  width: 100%;

  &:first-child {
    margin-top: 0;
  }
  .bg-opacity {
    position: absolute;
    height: 241px;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.329545);
    border-radius: 6px;
  }
`;
const ImageWrapperV2 = styled.div`
  width: 100%;
  display: inline-block;
  position:relative
  height: 241px;
  margin-bottom: 15px;
  @media (min-width: 1200px) {
    max-height: 300px;
  }

  @media (max-width: 767px) and (min-width: 530px) {
    height: 100%;
    width: 100%;
  }

  > img {
    cursor: default
    height: 241px;
    border-radius: 6px;
    width: 100%;
  }
`;
const ChallengeDateV2 = styled.div`
  font-size: 14px;
  letter-spacing: 0px;
  float: left;
  width: 100%;
  margin-top: 0;

  i {
    margin-right: 3px;
  }

  > div {
    float: left;
    width: 100%;
    > svg {
      width: 16px;
      margin-right: 4px;
      height: 15px;
      margin-top: 5px;
      float: left;
    }
  }

  > span {
    color: #fff;
    font-family: ${RegularFont};
    &:nth-child(2) {
      margin: 0 3px;
    }
  }
  line-height: 24px;
  display: flex;
  align-items: center;
`;
const ChallengeSvgContainer = styled.div`
  float: left;
  border-bottom: ${(props) => props.isSocialFeedsPage && "1px solid #e2e2e4"};
  display: flex;
  align-items: center;
  @media (max-width: 450px) {
    margin-bottom: 10px;
  }
  > span {
    font-size: 14px;
    letter-spacing: 0px;
    color: rgb(153, 153, 153);
  }

  > svg {
    margin-right: 8px;
  }

  > img {
    width: ${({ width }) => (width ? "20px" : "15px")};
    margin-right: 6px;
    margin-top: ${({ noTop }) => !noTop && "-4px"};
  }
`;
const EventJoiningTime = styled.div`
  float: ${({ right }) => (right ? "right" : "left")};
  text-align: ${({ right }) => (right ? "right" : "left")};
  color: #fff;
  margin-left: auto span {
    color: #fff;
    font-size: 16px;
    font-family: ${BoldFont};
  }
  @media (max-width: 1100px) and (min-width: 1000px) {
    float: none;
    text-align: left;
    margin-left: 0 br {
      display: none;
    }
  }
  @media (max-width: 550px) {
    float: none;
    text-align: left;
    margin-left: 0 br {
      display: none;
    }
  }
`;
const ChallengeGrid = styled.div`
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: ${({ wrap }) => (wrap ? wrap : "")};
`;
const ChallengeInfoGrid = styled.div`
  float: left;
  width: ${({ width }) => (width ? "60%" : "55%")};
  margin-right: 20px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const ChallengeInfoGridV2 = styled.div`
  float: left;
  width: ${({ width }) => (width ? width : "55%")};
  margin-right: ${({ width }) => (width ? "0px" : "20px")};
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const ChallengeInfoGridNew = styled.div`
  float: left;
  width: ${({ width }) => (width ? "63.9%" : "63.9%")};
  max-width: 771px;
  margin-right: 25px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const CommentGrid = styled.div`
  float: left;
  width: ${({ width }) => (width ? "calc(40% - 20px)" : "calc(45% - 20px)")};
  background: #efeff0;
  border-radius: 6px;
  .contents-top {
    > div:first-child {
      > div:nth-child(2) {
        > div:first-child {
          height: auto;
        }
      }
    }
  }
  h3 {
    padding-left: 25px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 10px;
  }
`;
const ChatGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 65%;
  width: ${({ width }) => (width ? width : "65%")}
  float: right;
  background: #1C4667;
  border-radius: 5px;
  color: #fff;
  @media (max-width: 1225px){
     width: 100%;
  }
  @media (max-width: 500px){
     width: 100%;
  }
  & > div {
    font-family: ${RegularFont}
    > span {
      font-size: 16px;
    }
    > div {
      font-size: 13px;
      display: flex;
      > span{
        margin-left: 4px;
      }
    }
  }
`;
const ChatUsers = styled.div`
  display: flex;
  align-items: center;
`;
const ChatTextArea = styled(CustomTextArea)`
  border-radius: 50px;
  border: none;
  width: 100%;
  padding: 15px 55px 15px 20px;
  @media (max-width: 500px) {
    padding: 12px 50px 12px 20px;
    width: 100%;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
`;
const ChatCommentContent = styled(CommentContent)`
  background-color: white;
  & > span {
    position: absolute;
    width: 25px;
    right: 35px;
    top: 27px;
    cursor: pointer;
    > img {
      width: 25px;
      height: 25px;
      float: left;
    }
    ${({ disabled }) =>
    disabled &&
      css`
        cursor: not-allowed !important;
        opacity: 0.5;
        width: auto;
        padding: 0 20px;
      `}
  }

  .InputImageSection {
    > img {
      width: 100px;
      height: 100px;
      margin: 0px 10px 10px 25px;
      float: left;
    }
  }
`;
const EditButton = styled(UserButton)`
  background: transparent;
  color: #fff;
  font-size: 13px;
  &:hover,
  &:active,
  &:focus {
    background: #ef6827;
  }
`;
const IconContainer = styled.div`
  display: ${({ cameraIcon }) => (cameraIcon ? "contents" : "block")}
  width: 100%;
  height: auto;
  float: inherit;
`;

const IconContainerNew = styled.div`
  width: unset !important;
  position: absolute;
  right: 4px;
  bottom: 4px;
`;

const InputImage = styled(ImageInput)`
margin-top:15px;
background-color: white;
border-radius: 0;
top:0 !important;
left:0 !important;
margin-bottom: 0;
> img {
  color: #dddddd;
 width: 100px;
 height: 100px;
 margin-right: 10px;
}
`;

const InputImageNew = styled(ImageInput)`
  padding: 8px 10px;
  background-color: white;
  border-radius: 0px;
  float: right;
  margin-bottom: 0px;
  margin-top: 0px > i {
    color: #dddddd;
    font-size: 25px;
  }
`;

const CommentContentContainerNew = styled.div`
  float: left;
  width: 100%
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const FavoriteContainer = styled.div`
  width: 100%;

  > div:first-child {
    width: 100%;
    text-transform: capitalize;
    font-family: Rubik-Medium;
    font-weight: 500;
    font-size: 20px;
    font-size: ${({ font }) => (font ? font : "24px")};
    line-height: 24px;
    color: #0d4270;
    padding-bottom: 25px;
    padding-top: 0px;
  }
  > div:last-child {
    width: 100%;
    margin-top: 0px;
    border-radius: 6px 6px 6px 6px;
    .image {
      max-width: 771px;
      height: 484px;
      background: rgba(156, 156, 156, 0.1);
      border-radius: 6px;
      > img {
        width: 100%;
        height: 484px;
        border-radius: 6px;
      }
    }
    .days {
      width: 100%;
      display: flex;
      align-items: center;
      align-items: center;
      justify-content: center;
      margin: auto;
      background: rgba(156, 156, 156, 0.1);
      margin-top: 5px;
      border-radius: 6px;
      > span {
        font-family: "Rubik-Regular";
        font-size: 18px;
        line-height: 24px;
        color: #fd7175;
        padding: 8px;
      }
    }
  }
`;

const EventDateContainerNew = styled.div`
  width: 100%;
  margin-bottom: 15px;
  background: white;
  border-radius: 6px;
  height: 80px;
  cursor: ${({ cursor }) => (cursor ? "pointer" : "")};
  >div: first-child {
    width: 100%;
    display: flex;

    div: first-child {
      display: flex;
      justify-content: flex-start;
      width: 50%;

      font-size: 16px;
      font-family: rubik;
      color: #0d4270;
      margin-left: 15px;
      padding: 8px 0px;
      line-height: 20px;
    }
    div: last-child {
      display: flex;
      justify-content: flex-end;
      width: 50%;
      margin-right: 15px;

      font-size: 16px;
      font-family: "rubik-medium";
      color: #0d4270;
      padding: 8px 0px;
      line-height: 20px;
    }
  }
  >div: last-child {
    width: 100%;
    display: flex;

    div: first-child {
      display: flex;
      justify-content: flex-start;
      width: 50%;

      font-size: 16px;
      font-family: rubik;
      color: #0d4270;
      margin-left: 15px;
      padding: 8px 0px;
      line-height: 20px;
    }
    div: last-child {
      display: flex;
      justify-content: flex-end;
      width: 50%;

      font-size: 16px;
      font-family: "rubik-medium";
      color: #0d4270;
      margin-right: 15px;
      padding: 8px 0px;
      line-height: 20px;
    }
  }
`;

const TaskContainer = styled.div`
  width: 100%;
  margin-bottom: 15px;
  background: white;
  border-radius: 6px;

  cursor: ${({ cursor }) => (cursor ? "pointer" : "")};
  >div: first-child {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-family: rubik-medium;
    padding: 15px;
    color: #0d4270;
    line-height: 21px;
  }
  >div: last-child {
    width: 100%;
    display: flex;
    padding-bottom: 15px;
    div: first-child {
      width: 100%;
      display: flex;
      justify-content: center;
      > button {
        border-radius: 5px;
        width: 53.75%;
        display: flex;
        justify-content: center;
        font-size: 16px;
        line-height: 19px;
        font-family: rubik-medium;
        padding: 0px;
        color: #fff;
        border: none;
        height: 40px;
        justify-content: center;
        align-items: center;
        background: rgba(156, 156, 156, 0.4);
        background: ${({ noButton }) =>noButton? "#69C2FF": "linear-gradient(90deg,rgb(0,177,188) -1.11%,rgb(117,217,163) 97.46%)"};
      }
    }
  }
`;

const TeamMembers = styled.div`
  width: 100%;
  .title {
    font-size: 18px;
    color: #0d4270;
    line-height: 24px;
  }

  .container {
    width: 100%;
    margin-bottom: 15px;
    background: white;
    border-radius: 0px 0px 6px 6px;
    border-top: 1px solid #005C874D;
  }

  .teamChallenge {
    .image {
      > img {
        width: auto;
        height: 60px;
        margin: auto;
        margin: 15px 5px 15px 0px;
      }
    }
    .field {
      font-family: Rubik;
      font-size: 16px;
      line-height: 20px;
      color: #0d4270;
    }

    .value {
      font-family: Rubik-Medium;
      font-size: 16px;
      line-height: 20px;
      color: #005C87;
      font-weight: 500;
      margin-bottom:8px;
    }

    .border {
      width: auto;
      height: 1px;
      background: rgba(156, 156, 156, 0.3);
    }
  }
`;

const EventDateContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  background: white;
  border-radius: 6px;
  height: 90px;

  >div: first-child {
    width: 10%;
    display: flex;
    > img {
      width: auto;
      height: 60px;
      margin: auto;
      margin: 15px 5px 15px 15px;
    }
  }
  >div: last-child {
    width: 90%;
    display: block;
    margin: auto;
    >div: first-child {
      width: 100%;
      display: flex;
      padding: 8px 15px 10px 10px;
      justify-content: space-between;
      cursor: ${({ cursor }) => (cursor ? "pointer" : "")};
      >div: first-child {
        width: 40%;
        display: flex;
        justify-content: flex-start;
        font-size: 16px;
        font-family: rubik;
        color: #0d4270;
        line-height: 20px;
      }
      >div: last-child {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        font-size: 16px;
        font-family: rubik-medium;
        color: #0d4270;
        color: ${({ color }) => (color ? color : "#9FC989")};
        text-transform: capitalize;
        line-height: 20px;
      }
    }
    >div: last-child {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px 15px 8px 10px;
      >div: first-child {
        width: 40%;
        display: flex;
        justify-content: flex-start;
        font-size: 16px;
        font-family: rubik;
        color: #0d4270;
      }

      >div: last-child {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        font-size: 16px;
        font-family: rubik-medium;
        color: #0d4270;
        color: ${({ color }) => (color ? color : "#9FC989")};
      }
      .locationPlace {
        color: #0d4270;
      }
    }
  }
`;

const PeopleOverview = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  background: white;
  border-radius: 6px;
  ${"" /* height: 90px; */}

  .peopleoverviewimg {
    width: 10%;
    display: flex;
    ${"" /* align-items:center; */}
    >img {
      width: auto;
      height: 60px;
      margin: auto;
      margin: 10px 5px 15px 15px;
    }
  }
  .details {
    width: 90%;
    display: block;
    margin: auto;
    .teams {
      width: 100%;
      display: flex;
      padding: 8px 15px 10px 10px;
      justify-content: space-between;
      cursor: ${({ cursor }) => (cursor ? "pointer" : "")};
      >div: first-child {
        width: 40%;
        display: flex;
        justify-content: flex-start;
        font-size: 16px;
        font-family: rubik;
        color: #0d4270;
        line-height: 20px;
      }
      >div: last-child {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        font-size: 16px;
        font-family: rubik-medium;
        color: #0d4270;
        color: ${({ color }) => (color ? color : "#9FC989")};
        text-transform: capitalize;
        line-height: 20px;
      }
    }

    .participants {
      width: 100%;
      display: flex;
      padding: 8px 15px 10px 10px;
      justify-content: space-between;
      cursor: ${({ cursor }) => (cursor ? "pointer" : "")};
      >div: first-child {
        width: 40%;
        display: flex;
        justify-content: flex-start;
        font-size: 16px;
        font-family: rubik;
        color: #0d4270;
        line-height: 20px;
      }
      >div: last-child {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        font-size: 16px;
        font-family: rubik-medium;
        color: #0d4270;
        color: ${({ color }) => (color ? color : "#9FC989")};
        text-transform: capitalize;
        line-height: 20px;
      }
    }
    .buddies {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px 15px 8px 10px;
      >div: first-child {
        width: 40%;
        display: flex;
        justify-content: flex-start;
        font-size: 16px;
        font-family: rubik;
        color: #0d4270;
      }

      >div: last-child {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        font-size: 16px;
        font-family: rubik-medium;
        color: #0d4270;
        color: ${({ color }) => (color ? color : "#9FC989")};
      }
      .locationPlace {
        color: #0d4270;
      }
    }
  }
`;

const EventsButton = styled.div`
  width: ${({ width }) => (width ? "48%" : "100%")};
  font-size: 18px;
  font-size: ${({ font }) => (font ? font : "18px")};
  line-height: 24px;
  font-family: rubik-medium;
  color: ${({ color }) => (color ? color : "white")};
  padding: 13px 8px 13px 8px;
  padding: ${({ padding }) => (padding ? padding : "13px 8px 13px 8px")};
  display: flex;
  margin-bottom: ${({ margin }) => (margin ? margin : "15px")};
  justify-content: center;
  border-radius: 3px;
  cursor: ${({ cursor }) => (cursor ? "" : "pointer")};
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "white"};
  box-shadow: ${({ boxShadow }) => (boxShadow ? boxShadow : "")};
  > a {
    text-decoration: none;
    color: white;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const StyledTabsNew = styled(StyledTabContainer)`
  width: 100%;
  .tab-content {
    position: relative;
  }
  
  .company-container {
    float: right;
    margin-top: -53px;
    margin-right: 43px;
    
    @media (max-width: 639px) {
      float: left;
      margin-left: 63px;
    }
    @media (max-width: 800px) {
      float: right;
      
    }
    @media (max-width: 500px) {
      display: inline-block;
      float: none;
      margin: -27px 41px 0 33px;
      padding-top: 10px;
    }
    @media (max-width: 600px) and (min-width: 501px) {
        display: inline-block;
        float: right;
        margin: 0 41px 0 33px;
        padding-top: 10px;
    }
  }
  
  > ul {
    margin: 0
    border-radius: 6px;
    padding: 10px 15px 10px 15px;
    @media (max-width: 600px) {
      min-height: 100px;
      margin-top: 30px;
      padding: 4px 0px;
    }
    > li {
      padding: 0px 30px;
      display: flex;
      justify-content: center;
      height: 30px;
      @media (max-width: 600px) {
        text-align: center;
        float: none;
        width: 107px;
        margin: 0 auto;
      }
      > a {
        font-size: 15px;
        color: #CCCBDO;
        text-transform: none;
        font-family: ${BoldFont};
        margin-right: 0px;
        display: flex;
        align-items: center;
        @media (max-width: 600px) {
        padding-left: 0;
        padding-right: 0
        margin: 0;
        padding: 10px 0px;

        font-family: 'Rubik-Regular';
        font-size: 16px;
        line-height: 20px;
        color:#9C9C9C;
       }
      }
    }

    .active {
      > a {
        &:hover, &:active, &:focus {
          color: rgb(51,51,51);
        }
      }
    }
  }


  li.nav-item {
      @media (max-width: 600px) {
        float: none;
        width: 135px;
        margin: 0 auto;
      }
      >button {
				margin-right: 1vw;
        font-size: 16px;
        color: rgb(102, 157, 183);
        text-transform: none;
        font-family: ${RegularFont};
        
        @media (max-width: 600px) {
        padding-left: 0;
        padding-right: 0;
        margin: 0;
       }
      }
    }

  li.nav-item {
    button.first-tab.nav-link.active  {
    background: rgb(242, 247, 249);
    border-radius: 6px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: rgb(0, 92, 135);
    justify-content: center;
    padding: 10px;
    border-bottom: 3px solid  none;
    border: none;
    ${'' /* margin-top: -5px; */}
    }
  }
  li.nav-item {
    button.second-tab.nav-link.active  {
    background: rgb(242, 247, 249);
    border-radius: 6px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: rgb(0, 92, 135);
    justify-content: center;
    padding: 10px;
    border-bottom: 3px solid  none;
    border: none;
    ${'' /* margin-top: -5px; */}
    }
  }
  li.nav-item {
    button.third-tab.nav-link.active  {
    background: rgb(242, 247, 249);
    border-radius: 6px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: rgb(0, 92, 135);
    justify-content: center;
    padding: 10px;
    border-bottom: 3px solid  none;
    border: none;
    ${'' /* margin-top: -5px; */}
    }
  }

  li.first-tab.active {
    > a {
      border-bottom: none;
      padding: 10px 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color:#0D4270;
    }
  }
  li.second-tab.active {
    > a {
      border-bottom: none;
      padding: 10px 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color:#0D4270;
    }
  }
  li.third-tab.active {
    > a {
      border-bottom: none;
      padding: 10px 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color:#0D4270;
    }
  }
  li.first-tab.active {
      background: rgba(0, 47, 71, 0.1);
      border-radius: 3px;
  }
  li.second-tab.active {
      background: rgba(0, 47, 71, 0.1);
      border-radius: 3px;
  }
  li.third-tab.active {
      background: rgba(0, 47, 71, 0.1);
      border-radius: 3px;
  }
  > ul > li > a{
    padding: 0px;

    font-family: 'Rubik-Regular';
    font-size: 18px;
    line-height: 21px;
    color:#9C9C9C;
  }
`;

const LayoutNew = styled.div`
  max-width: 1246px;
  width: 100%;
  font-family: ${RegularFont};
  margin-bottom: 25px;
  display: flex;
  display: ${({ display }) => (display ? display : "flex")};
  margin: auto;

  @media (max-width: 500px) {
    padding: 30px 10px;
  }
`;

const Main = styled.div`
  width: 100%;
  padding: 25px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-bottom: 15px;
  margin-top: ${({ margin }) => (margin ? margin : "0px")};
`;

const CommentGridNew = styled.div`
  float: left;
  width: ${({ width }) => (width ? "100%" : "100%")};
  border-radius: 6px;
  .contents-top {
    > div:first-child {
      > div:nth-child(2) {
        > div:first-child {
          height: auto;
        }
      }
    }
  }
  h3 {
    padding-left: 25px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 10px;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  background: white;
  border-radius: 6px;
  margin-bottom: 15px;
  padding-bottom: 8px;
`;

const TableHeading = styled.div`
  width: 100%;
  background: white;
  display: flex;
  > div:nth-child(1) {
    width: 17%;
    height: 45px;
  }
  > div:nth-child(2) {
    width: 17%;
    font-family: Rubik;
    font-size: 14px;
    line-height: 15px;
    color: #005C8799;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > div:nth-child(3) {
    width: 33%;
    font-family: Rubik;
    font-size: 14px;
    line-height: 15px;
    color: #005C8799;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > div:nth-child(4) {
    width: 33%;
    font-family: Rubik;
    font-size: 14px;
    line-height: 15px;
    color: #005C8799;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const TableData = styled.div`
  width: 100%;
  background: linear-gradient(
    90deg,
    rgb(0, 177, 188, 00.1) -1.11%,
    rgb(117, 217, 163, 00.1) 97.46%
  );
  background: ${({ background }) =>
    background ? background : "#005C870D"};
  border-left: 3px solid rgb(117, 217, 163);
  border-left: ${({ border }) =>
    border ? border : "4px solid #85C0EA"};
  margin-bottom: 6px;
  display: flex;
  > div:nth-child(1) {
    width: 17%;
    display: flex;
    margin: auto;
    justify-content: center;
    .circleBorder{
      border: 1px solid #005C8799;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display:flex;
    > img {
      width: 20px;
      height: 20px;
      margin:auto;
      display: flex;
    }
  }
  }
  > div:nth-child(2) {
    width: 17%;
    font-family: "rubik";
    font-size: 16px;
    line-height: 15px;
    color: #005C87;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > div:nth-child(3) {
    width: 33%;
    font-family: "Rubik-Medium";
    font-size: 16px;
    line-height: 15px;
    color: #005C87;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > div:nth-child(4) {
    width: 33%;
    font-family: "Rubik-Medium";
    font-size: 16px;
    line-height: 15px;
    color: ${({ color }) => (color ? color : "#005C87")};
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const FavoriteContainerV2 = styled.div`
width: 100%;

>div:first-child{
 width: 100%;
 text-transform: capitalize;
 font-family: Rubik-Medium;
 font-weight: 500;
 font-size: 24px;
 line-height: 24px;
 color: #005c87;
 padding-bottom: 25px;
 padding-top: 0px;
}
>div:last-child{
  width: 100%;
  margin-top: 0px;
  background: transparent;
  border-radius:6px 6px 6px 6px;
  .image{
    max-width: 771px;
    height: 484px;
    >img{
      width: 100%;
      height: 484px;
      border-radius: 6px;
    }
  }

  .days{
    height: 49px
    width: 100%;
    display: flex;
    align-items:center;
    align-items: center;
    justify-content: center;
    margin: auto;
    >span{
      font-family: 'Rubik-Regular';
      font-size: 18px;
      line-height: 18px;
      color: #FD7175;
      padding: 15px;
    }
  }
  
}
`;

const CompletedChallengeCard = styled.div`
  display: flex;
  width: 100%;
  background: white;
  padding:15px 75px;
  justify-content: center;
  align-items: center;
  margin-bottom 15px;
  >font{
    font-family: "Rubik";
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  >b{
    font-size: 16px;
    line-height: 20px;
  }
}
`;

const TabContainer = styled.div`
display: flex;
width: 100%;
background: white;
border-radius: 6px;
margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : "0px")};
margin-top: ${({ marginTop }) => (marginTop ? marginTop : "0px")};
padding-right: ${({ paddingRight }) => (paddingRight ? paddingRight : "0px")};
.year-dropdown{
  display: flex;
  margin: auto;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
 }
.radio-btn{
  width: 24px;
  height: 24px;
  left: 1516px;
  top: 278px;
  border: 1px solid #9C9C9C;
  border-radius: 21px;
  display: flex;
  align-items: center;
  justify-content:center;
  margin-top: 23px;
  cursor: pointer;

}
.innerCircle{
  display:${({ show }) => (show ? "block" : "none")}
  width: 16px;
  height: 16px;
  background: #FD7175;
  border-radius: 15px;
  cursor: pointer;
}
.bulk-bttn{
  font-family: 'Rubik-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #0D4270;
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-left: 9px;
}
}
.backButton{
  display:flex;
  >div{
    align-items:center;
  }
}
`;

const TabView = styled.div`
display: flex;
width: ${({ width }) => (width ? "auto" : "130px")};
height: ${({ height }) => (height ? height : "36px")};
padding: ${({ padding }) => (padding ? "10px 15px" : "")};
background: ${({active}) => active ? '#007AB1' : 'white'};
border-radius: 6px;
font-family: ${({ active }) => (active ? "Rubik-Medium" : "Rubik")};
font-size: 16px;
line-height: 19px;
color: ${({active}) => active ? '#FFFFFF' : '#007AB1'};
margin: ${({margin}) => margin ? margin : '10px 15px'};
justify-content: center;
align-items: center;
cursor: pointer;

:hover{
  background:#005C87;
  color:#ffff;
  ${'' /* font-family:Rubik-Medium; */}
}
}
`;

const BackButton = styled.div`
display: flex;
width: ${({ width }) => (width ? "auto" : "40px")};
height: 40px;
padding: ${({ padding }) => (padding ? "10px 15px" : "")};
background: ${({ active }) => (active ? "#005C870D" : "white")};
border-radius: 6px;
font-family: ${({ active }) => (active ? "Rubik-Medium" : "Rubik")};
font-size: 16px;
line-height: 19px;
color: ${({ active }) => (active ? "#005C87" : "#005C8799")};
margin: 10px;
justify-content: center;
align-items: center;
cursor: pointer;
>svg{
  height:20px;
  width:20px;
  >g{
    >g{
      fill: #005C87;
    }
  }
}
}
`;

const ChallengeTopImage = styled.div`
  width: 100%;

  > div:first-child {
    width: 100%;
    text-transform: capitalize;
    font-family: Rubik-Medium;
    font-weight: 500;
    font-size: 20px;
    font-size: ${({ font }) => (font ? font : "24px")};
    line-height: 24px;
    color: #005c87;
    padding-bottom: 25px;
    padding-top: 0px;
  }
  > div:last-child {
    width: 100%;
    margin-top: 0px;
    border-radius: 6px 6px 6px 6px;
    .challengeType {
      height: 0px;
      width: 100%;
      position: relative;
      left: 10px;
      top: 10px;
      .label {
        height: 24px;
        min-width: 120px;
        max-width:150px;
        display: flex;
        background: white;
        border-radius: 3px;
        font-family: Rubik;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: center;
        color: #005c87;
        position: relative;
        align-items: center;
        justify-content: center;
        top: 10px;
        left: 10px;
        padding: 0px 10px;
      }
    }
    .image {
      max-width: 771px;
      height: 484px;
      background: rgba(156, 156, 156, 0.1);
      border-radius: 6px;
      > img {
        width: 100%;
        height: 484px;
        border-radius: 6px;
      }
    }
    .days {
      width: 100%;
      display: flex;
      align-items: center;
      align-items: center;
      justify-content: center;
      margin: auto;
      background: white;
      margin-top: 15px;
      border-radius: 6px;
      > span {
        font-family: "Rubik-Regular";
        font-size: 18px;
        line-height: 24px;
        color: #76ab78;
        padding: 8px;
      }
    }
  }
`;

const ChallengeDateContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  background: white;
  border-radius: 6px;
  height: 90px;

  >div: first-child {
    width: 10%;
    display: flex;
    > img {
      width: auto;
      height: 60px;
      margin: auto;
      margin: 15px 5px 15px 15px;
    }
  }
  >div: last-child {
    width: 90%;
    display: block;
    margin: auto;
    >div: first-child {
      width: 100%;
      display: flex;
      padding: 8px 15px 10px 10px;
      justify-content: space-between;
      cursor: ${({ cursor }) => (cursor ? "pointer" : "")};
      >div: first-child {
        width: 40%;
        display: flex;
        justify-content: flex-start;
        font-size: 16px;
        font-family: rubik;
        color: #005c87;
        line-height: 20px;
      }
      >div: last-child {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        font-size: 16px;
        font-family: rubik-medium;
        color: #005c87;
        color: ${({ color }) => (color ? color : "#005C87")};
        text-transform: capitalize;
        line-height: 20px;
      }
    }
    >div: last-child {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px 15px 8px 10px;
      >div: first-child {
        width: 40%;
        display: flex;
        justify-content: flex-start;
        font-size: 16px;
        font-family: rubik;
        color: #005c87;
      }

      >div: last-child {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        font-size: 16px;
        font-family: rubik-medium;
        color: ${({ color }) => (color ? color : "#005C87")};
      }
      .locationPlace {
        color: #005c87;
      }
    }
  }
`;

const ChallengePointContainer = styled.div`
  width: 100%;
  margin-bottom: 15px;
  background: white;
  border-radius: 6px;
  height: 80px;
  cursor: ${({ cursor }) => (cursor ? "pointer" : "")};
  >div: first-child {
    width: 100%;
    display: flex;

    div: first-child {
      display: flex;
      justify-content: flex-start;
      width: 50%;

      font-size: 16px;
      font-family: rubik;
      color: #005c87;
      margin-left: 15px;
      padding: 8px 0px;
      line-height: 20px;
    }
    div: last-child {
      display: flex;
      justify-content: flex-end;
      width: 50%;
      margin-right: 15px;

      font-size: 16px;
      font-family: "rubik-medium";
      color: #005c87;
      padding: 8px 0px;
      line-height: 20px;
    }
  }
  >div: last-child {
    width: 100%;
    display: flex;

    div: first-child {
      display: flex;
      justify-content: flex-start;
      width: 50%;

      font-size: 16px;
      font-family: rubik;
      color: #005c87;
      margin-left: 15px;
      padding: 8px 0px;
      line-height: 20px;
    }
    div: last-child {
      display: flex;
      justify-content: flex-end;
      width: 50%;

      font-size: 16px;
      font-family: "rubik-medium";
      color: #005c87;
      margin-right: 15px;
      padding: 8px 0px;
      line-height: 20px;
    }
  }
`;

const IconWrapper = styled.div`
  display:flex;
  margin: ${({ margin }) => (margin ? margin : "0")};
  >div{
    >div{ 
      margin: ${({ marginLeft }) => (marginLeft ? "0 0 0 15px !important" : "")};
    }
  }
  .title{
    font-family: Rubik-Medium;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #005c87;
    display:flex;
    margin: auto auto auto 15px; 
  }
`;

const CompletePointCard = styled.div`
  display:flex;
  width:100%;
  background:white;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: #005C87;
  margin-bottom:15px;
  justify-content:center;
  padding:15px;
  flex-wrap:wrap;
  border-radius:6px;
  .width{
    width:100%;
    display:flex;
    justify-content:center;
  }
.value{
  font-family: Rubik-Medium;
  font-size: 16px;
  line-height: 24px;
  color: #DC94B5;
  }
`;

const HoverIconWrapper = styled.div`
  display:flex;
  width:100%;
  justify-content:flex-end;
`;

export {
  CommentInnerContainer,
  FormContainer,
  TopContainer,
  Button,
  DescriptionTraining,
  HeaderDashboard,
  DateShower,
  DateCircle,
  WeekDashboard,
  ChallengeDate,
  ChallengeContentContainer,
  ChallengeDescription,
  ContentSection,
  SectionHeader,
  BehindContent,
  Ranking,
  RankContainer,
  ActivityBar,
  InnerActivityBar,
  ActivityContainer,
  UserName,
  CoveredChallenge,
  LeaderBoardSection,
  ChallengeDetailsContainer,
  Description,
  Layout,
  ImageWrapper,
  CommentContentContainer,
  LeaderBoardItem,
  LeaderboardItemContainer,
  ProfileImage,
  ChallengeContentLeftContainer,
  TopRowContainer,
  ChallengeTitle,
  SyncTimeContainer,
  StyledTabs,
  ChallengeDetailsContainerV2,
  ImageWrapperV2,
  ChallengeDateV2,
  ChallengeSvgContainer,
  EventJoiningTime,
  ChallengeGrid,
  ChallengeInfoGrid,
  CommentGrid,
  ChatGroup,
  ChatUsers,
  ChatTextArea,
  ChatCommentContent,
  EditButton,
  IconContainer,
  InputImage,
  CommentContentContainerTeam,
  CommentContentContainerNew,
  FavoriteContainer,
  EventDateContainerNew,
  TaskContainer,
  EventDateContainer,
  EventsButton,
  StyledTabsNew,
  LayoutNew,
  Main,
  ButtonContainer,
  CommentGridNew,
  ChallengeInfoGridNew,
  IconContainerNew,
  InputImageNew,
  TableContainer,
  TableHeading,
  TableData,
  ChallengeInfoGridV2,
  FavoriteContainerV2,
  CompletedChallengeCard,
  PeopleOverview,
  TeamMembers,
  TabContainer,
  TabView,
  BackButton,
  ChallengeTopImage,
  ChallengeDateContainer,
  ChallengePointContainer,
  IconWrapper,
  CompletePointCard,
  HoverIconWrapper
};
